<script setup>
import {computed} from 'vue';
import {Link as InertiaLink} from '@inertiajs/vue3';

const props = defineProps({
    href: {
        type: String,
        required: true,
    },
    active: Boolean,
});

const classes = computed(() => {
    return props.active
        ? 'inline-flex items-center px-1 pt-1 border-b-2 border-primary text-sm font-medium leading-5 text-base-content focus:outline-none focus:border-primary/70 transition duration-150 ease-in-out'
        : 'inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-base-content/50 hover:text-base-content hover:border-primary/30 focus:outline-none focus:text-base-content/70 focus:border-base-content/30 transition duration-150 ease-in-out';
});
</script>

<template>
    <InertiaLink :href="href" :class="classes">
        <slot />
    </InertiaLink>
</template>
