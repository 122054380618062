<script setup>
import {computed} from 'vue';
import {Link as InertiaLink} from '@inertiajs/vue3';

const props = defineProps({
    active: Boolean,
    href: {
        type: String,
        default: null,
    },
    as: {
        type: String,
        default: null,
    },
});

const classes = computed(() => {
    return props.active
        ? 'block w-full ps-3 pe-4 py-2 border-l-4 border-indigo-400 dark:border-indigo-600 text-start text-base font-medium text-indigo-700 dark:text-indigo-300 bg-indigo-50 dark:bg-indigo-900/50 focus:outline-none focus:text-indigo-800 dark:focus:text-indigo-200 focus:bg-indigo-100 dark:focus:bg-indigo-900 focus:border-indigo-700 dark:focus:border-indigo-300 transition duration-150 ease-in-out'
        : 'block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 dark:hover:border-gray-600 focus:outline-none focus:text-gray-800 dark:focus:text-gray-200 focus:bg-gray-50 dark:focus:bg-gray-700 focus:border-gray-300 dark:focus:border-gray-600 transition duration-150 ease-in-out';
});
</script>

<template>
    <div>
        <button v-if="as === 'button'" :class="classes" class="w-full text-start">
            <slot />
        </button>

        <InertiaLink v-else :href="href" :class="classes">
            <slot />
        </InertiaLink>
    </div>
</template>
